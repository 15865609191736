import React from "react"
import Head from "../components/Head"
import { ThankYou } from "../components/ThankYou"

export default function ThankYouComplete() {
  return (
    <>
      <Head />
      <ThankYou />
    </>
  )
}
